<script setup>
import { useForm } from '@inertiajs/vue3'
import VInput from '@/Components/BaseComponents/VInput.vue'
import VButton from '@/Components/BaseComponents/VButton.vue'
import { trans } from '@/vendor/inertia-scaffold/trans.js'
import BaseAuthentication from '@/Pages/Auth/Numnum/BaseAuthentication.vue'

defineProps({
    canResetPassword: Boolean,
    status: String,
    page: Object,
})

const form = useForm({
    email: '',
    password: '',
    remember: false,
})

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    })
}
</script>

<template>
    <base-authentication>
        <template #form>
            <form @submit.prevent="submit">
                <div>
                    <v-input
                        class="!grid grid-cols-4"
                        label-classes="col-span-1"
                        input-classes="col-span-3"
                        error-classes="col-start-2 col-span-3"
                        :label="trans('auth.email')"
                        type="email"
                        :name="'email'"
                        v-model="form.email"
                        id="email"
                        autofocus
                        autocomplete="username"
                        :placeholder="trans('auth.email')"
                    />
                </div>

                <div class="mt-4">
                    <v-input
                        class="!grid grid-cols-4"
                        label-classes="col-span-1"
                        input-classes="col-span-3"
                        error-classes="col-start-2 col-span-3"
                        :label="trans('auth.password')"
                        type="password"
                        name="password"
                        v-model="form.password"
                        id="password"
                        required
                        autocomplete="current-password"
                        :placeholder="trans('auth.password')"
                    />
                </div>
                <div class="mt-6">
                    <div class="lg:flex lg:items-baseline lg:justify-between">
                        <v-button
                            type="submit"
                            kind="primary"
                            class="!bg-[#5400cf] hover:!bg-[#6903ff]"
                        >
                            {{ trans('auth.login') }}
                        </v-button>
                        <v-button
                            :href="route('password.request')"
                            kind="info"
                            class="font-11 !px-0 max-w-max"
                        >
                            {{ trans('auth.password_forgotten') }}
                        </v-button>
                    </div>
                </div>
            </form>

            <p class="text-info-text text-[13px] mt-6">
                {{ trans('auth.no_account_yet') }}
                <v-button
                    kind="link"
                    :href="route('register')"
                    class="!text-info-text !px-1 !normal-case !inline-block !shadow-none !text-[13px]"
                >
                    {{ trans('auth.click_to_register') }}
                </v-button>
            </p>
        </template>
    </base-authentication>
</template>
