import { usePage } from '@inertiajs/vue3'

export function trans(key, replacements = {}) {
    const page = usePage()
    let translations = JSON.parse(page.props.translations)
    let translation = translations[key] || key
    if (Object.keys(replacements).length > 0) {
        for (let [k, v] of Object.entries(replacements)) {
            translation = translation.replace(`:${k}`, v)
        }
    }

    // if (translation == key) {
    //     fetch('/scaffold/translation/' + key)
    //     //explode the key and return element 1
    //     let exploded = key.split('.')
    //     return exploded[1]
    // }

    return translation
}
