import '../css/variables.scss'
import '../css/global.scss'
import '../css/scaffold.scss'
import './bootstrap'
import '../css/app.css'

import { createApp, h, watch } from 'vue'
import { createInertiaApp, usePage } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import vSelect from 'vue-select'
import { createPinia } from 'pinia'
import 'vue-select/dist/vue-select.css'
import { modal } from '@/vendor/inertia-modal.js'
import '../../node_modules/flowbite-vue/dist/index.css'

let title = import.meta.env.VITE_APP_NAME || 'Laravel'
const appName = title.replaceAll('_', ' ')
const page = usePage()
watch(
    () => page.props,
    (props) => {
        if (props && props.theme) {
            switch (props.theme) {
                case 'sage':
                    import('../css/sage-variables.scss')
                    break
                case 'wings':
                    import('../css/wings-variables.scss')
                    break
                default:
                    import('../css/numnum-variables.scss')
                    break
            }
        } else {
            import('../css/numnum-variables.scss')
        }
    },
    { immediate: true }
)

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const numnumApp = createApp({ render: () => h(App, props) })
            .use(modal, {
                resolve: (name) =>
                    resolvePageComponent(
                        `./Pages/${name}.vue`,
                        import.meta.glob('./Pages/**/*.vue')
                    ),
            })
            .use(plugin)
            .use(ZiggyVue)
            .use(LaravelPermissionToVueJS)
            .use(createPinia())
            .component('v-select', vSelect)
        return numnumApp.mount(el)
    },
    progress: {
        delay: 250,
        color: '#29d',
        includeCSS: true,
        showSpinner: false,
    },
})
