<script setup>
import { onMounted, ref } from 'vue'
import { random } from 'lodash'
import { usePage } from '@inertiajs/vue3'

const page = usePage()
const bgImage = ref(null)
onMounted(() => {
    if (page.props.theme === 'sage') {
        bgImage.value.classList.add('bg-image-' + random(1, 3))
    } else {
        bgImage.value.classList.add('default-bg-image')
    }
})
</script>
<template>
    <div class="min-h-screen flex flex-col items-center justify-center bg-image" ref="bgImage">
        <div>
            <slot name="logo" />
        </div>

        <div
            class="w-full mt-6 mx-3 px-8 py-4 bg-white md:shadow-md overflow-hidden md:rounded-lg card lg:w-2/5 md:w-1/2"
        >
            <slot name="card-header" />
            <slot />
        </div>
        <slot name="footer" />
    </div>
</template>
<style lang="scss">
@media (min-width: 768px) {
    .default-bg-image {
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
            url('/img/background.jpg');
    }

    .card {
        @apply rounded px-12 py-10;
        background-color: rgba(255, 255, 255, 0.9);
    }
}
</style>
