<script setup>
import { FwbTooltip } from 'flowbite-vue'
import ButtonContent from './ButtonContent.vue'

const props = defineProps({
    type: {
        type: String,
        default: 'button',
        required: false,
    },
    kind: {
        type: String,
        default: 'primary',
    },
    loading: {
        type: Boolean,
        default: false,
        required: false,
    },
    disabled: {
        type: Boolean,
        default: false,
        required: false,
    },
    href: {
        type: String,
        default: null,
        required: false,
    },
    class: {
        type: String,
        default: '',
        required: false,
    },
    method: {
        type: String,
        default: 'get',
        required: false,
    },
    tooltip: {
        type: String,
        default: null,
        required: false,
    },
    tooltip_placement: {
        type: String,
        default: 'left',
        required: false,
    },
})
</script>

<template>
    <fwb-tooltip :placement="props.tooltip_placement" theme="light" v-if="tooltip">
        <template #trigger>
            <ButtonContent
                :type="props.type"
                :kind="props.kind"
                :loading="props.loading"
                :disabled="props.disabled"
                :href="props.href"
                :class="props.class"
                :method="props.method"
                v-bind="$attrs"
            >
                <slot></slot>
            </ButtonContent>
        </template>
        <template #content>
            {{ tooltip }}
        </template>
    </fwb-tooltip>
    <template v-else>
        <ButtonContent
            :type="props.type"
            :kind="props.kind"
            :loading="props.loading"
            :disabled="props.disabled"
            :href="props.href"
            :class="props.class"
            :method="props.method"
            v-bind="$attrs"
        >
            <slot></slot>
        </ButtonContent>
    </template>
</template>
